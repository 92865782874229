.work-section-title {
    font-weight: lighter;
    font-size: 30px;
    color: var(--theme-green-color);
    font-weight: bold;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.work-section {
    margin-bottom: 50px;
}

.work-experience-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-row-gap: 45px;
    grid-column-gap: 30px;
}

.work-experience-image {
    width: 350px;
    cursor: pointer;
}

.work-experience-link {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 500px) {
    .work-experience-image {
        width: 200px;
    }
}

@media screen and (max-width: 300px) {
    .work-experience-image {
        width: 150px;
    }
}