.company-logo {
    width: 150px;
    height: 150px;
    display: inline-block;
}

.company-info {
    display: inline-block;
    margin-left: 10px;
}

.company-name {
    font-weight: bold;
    font-size: 100px;
}

.company-work-duration {
    font-weight: lighter;
    font-size: 20px;
    margin-bottom: 40px;
}

@media screen and (max-width: 500px) {
    .company-logo {
        width: 75px;
        height: 75px;
    }

    .company-name {
        font-size: 50px;
    }
}