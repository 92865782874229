.home-main {
    display: flex;
    flex-direction: row;
    margin: auto;
}

.home-profile-pic {
    width: 50%;
    display: flex;
}

.home-intro-text {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
}

.profile-pic {
    margin: auto;
    width: 80%;
    height: auto;
    border-radius: 50%;
}

.hello-text {
    font-size: 40px;
    color: #938D8D;
}

.name-text {
    font-weight: bold;
    font-size: 90px;
    display: inline-block;
}

.role-text {
    color: var(--theme-green-color);
    display: inline-block;
    font-weight: bold;
}

.description-text {
    font-size: 30px;
}

.description-text:last-of-type {
    margin-top: 20px;
}

.description-section {
    margin-top: auto;
}

@media screen and (max-width: 1100px) {
    .hello-text {
        font-size: 30px;
    }

    .name-text {
        font-size: 60px;
    }

    .description-text {
        font-size: 25px;
    }

    .description-text:last-of-type {
        margin-top: 40px;
    }
}

@media screen and (max-width: 768px) {
    .home-profile-pic {
        width: 0%;
        height: 0%;
    }
    .home-intro-text {
        width: 100%;
    }
    .description-section {
        margin-top: 0;
    }

    .home-main {
        flex-direction: column;
        height: 65vh;
        justify-content: center;
        align-items: center;
        margin: 0;
    }
}

