* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

:root {
  --theme-green-color: #98C6C6;
}

.App {
  margin: auto;
  max-width: 1000px;
  padding: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
