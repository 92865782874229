.footer {
    min-height: 80px;
    bottom: 0;
    margin-top: auto;
}

.footer-icon {
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    cursor: pointer;
}

.github-icon {
    margin-top: 8px;
}

@media screen and (max-width: 768px) {
    .footer {
        margin-top: 0;
    }

    .footer-icon {
        margin-right: 25px;
    }
}