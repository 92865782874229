.project-row {
    display: flex;
    padding: 5px 30px;
    border: 1px solid rgb(230, 227, 227);
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 10px;
}

.project-row:hover{
    transform: scale(1.02);
    box-shadow: 0 4px 8px 0 rgba(94, 93, 93, 0.2), 0 6px 20px 0 rgba(94, 93, 93, 0.2);
    color: var(--theme-green-color);
}

.project-image {
    width: 125px;
    height: 125px;
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
}

.project-text {
    font-size: 50px;
    font-weight: bold;
    display: inline-block;
    margin-left: 50px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    display: inline-block;
}

.colored-dot {
    color: var(--theme-green-color);
    display: inline-block;
    font-size: 50px;
}

@media screen and (max-width: 768px) {
    .project-text {
        font-size: 45px;
    }
    .colored-dot {
        font-size: 45px;
    }
}

@media screen and (max-width: 500px) {
    .project-image {
        width: 100px;
        height: 100px;
    }

    .project-text {
        font-size: 25px;
    }

    .colored-dot {
        font-size: 25px;
    }
}

@media screen and (max-width: 350px) {
    .project-image {
        width: 80px;
        height: 80px;
    }

    .project-text {
        font-size: 18px;
    }

    .colored-dot {
        font-size: 15px;
    }
}